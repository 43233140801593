import React from 'react';
import {useState} from 'react';
import {Link} from 'react-router-dom';

const ContactUs = () => {
    const [status, setStatus] = useState("Submit");
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus("Sending...");             
      const { fname, lname, telNum, eMail, desName, topic } = e.target.elements;      
      let details = {
        fname: fname.value,
        lname: lname.value,
        eMail: eMail.value,
        telNum: telNum.value,
        message: desName.value,
        topic: topic.value,        

      };
      let response = await fetch("http://localhost:3000/contactus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
      });
      setStatus("Submit");
      let result = await response.json();
      alert(result.status);
    };
 
    return(
        <section className="contact__container">
            <div className="contect___content">
                <h1><small>Contact Us</small><br /><big>Let’s Start a Conversation</big></h1>
                <br />
                <h3>See our platform in action</h3>
                <p>Request a personalized demo, or request a trial of TUNE’s partner marketing platform.</p>
                <br />
                <h3>See our platform in action</h3>
                <p>Request a personalized demo, or request a trial of TUNE’s partner marketing platform.</p>
                <br />    
                <h3>See our platform in action</h3>
                <p>Request a personalized demo, or request a trial of TUNE’s partner marketing platform.</p>
                <br />
                <h3>Contact Info</h3>
                <p><strong>India | Delivery Headquarter</strong>
                <br /><br />
                Himachal Pradesh, 174011
                </p>
                 
                <p><strong>Information and Sales</strong><br />
                <Link to="/">info.wanderic@gmail.com</Link><br />
                <Link to="/">+91 9876 54321</Link>
                </p>

            </div>


        <div className="contactForm__container formStyles">
            <h2>Please fill required details. </h2>
            <form onSubmit={handleSubmit}>
                <div className="container__row">
                    <div className="containerRow___column column--6">
                        <label>First name</label>
                        <input id="fname" type="text" />
                    </div>  

                    <div className="containerRow___column column--6">
                        <label>Last name</label>
                        <input id="lname" type="text" />
                    </div>  
                </div> 

                <div className="container__row">
                    <div className="containerRow___column column--6">
                        <label>Email address</label>
                        <input id="eMail" type="email" />
                    </div>  

                    <div className="containerRow___column column--6">
                        <label>Telephone number</label>
                        <input id="telNum" type="text" />
                    </div>  
                </div>  
               

                <div className="container__row">
                    <div className="containerRow___column column--6">
                        <label>Title</label>
                        <input id="titleContact" type="text" />
                    </div>  

                     <div className="containerRow___column column--6">
                        <label>Topic</label>
                        <select id="topic">
                            <option disabled value>Select topic</option>
                            <option value="General Enquiry">General Enquiry</option>
                            <option value="Write Experince">Write Experince</option>
                            <option value="Contribute">Contribute</option>
                            <option value="Other">Other</option>
                        </select>
                    </div> 
                </div> 

                <div className="container__row">
                    <div className="containerRow___column column--12">
                        <label>Description</label>
                        <textarea id="desName" ></textarea>
                    </div>
                </div>

                {/* <div className="container__row">
                    <div className="containerRow___column column--12">
                        <label>Your Image</label>
                        <div className="inputFile">
                            Select File <input id="fileImg"  type="file" />
                        </div>
                    </div>
                </div> */}

                <div className="container__row">
                    <div className="containerRow___column column--12">
                        <button id="formSubmit" type="submit">
                            {status}
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </section>
    )
}

export default ContactUs;