import React from 'react';
//import Instagram from '../functions/Instagram';

function AboutUs(){
    return(
        <div className="page_AboutUs">
            <h1>This is About Us Page</h1>
            {/* <Instagram /> */}
        </div>
    )
}

export default AboutUs;