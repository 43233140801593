import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import ItemImg1 from '../images/dest.jpg';
import ItemImg2 from '../images/camp.jpg';
import ItemImg3 from '../images/museum.jpg';
import ItemImg4 from '../images/trek.jpg';
// import { BsArrowLeft } from 'react-icons/bs';
// import { BsArrowRight } from 'react-icons/bs';


function OwlHero(){

    return(
        <OwlCarousel className='hero_owl' loop margin={15} items={3} dots={false} slideBy={1} nav>

             <div className='item'>
                 <Link to="/" className="item_grid itemContent" style={{ backgroundImage: `url(${ItemImg1})` }}>
                     <h4>Beating the heat</h4>
                     <h6>North Goa, India</h6>   
                 </Link>
            </div>

            <div className='item'>
                 <Link to="/" className="item_grid itemContent" style={{ backgroundImage: `url(${ItemImg2})` }}>
                     <h4>Mountain Fresh</h4>
                     <h6>Leh, India</h6>   
                 </Link>
            </div>

            <div className='item'>
                 <Link to="/" className="item_grid itemContent" style={{ backgroundImage: `url(${ItemImg3})` }}>
                     <h4>T-Rex Museum</h4>
                     <h6>Bangloru, India</h6>   
                 </Link>
            </div>

            <div className='item'>
                 <Link to="/" className="item_grid itemContent" style={{ backgroundImage: `url(${ItemImg4})` }}>
                    <h4>Trek Virgin Hills</h4>
                    <h6>Kullu, India  </h6>
                 </Link>
            </div>

            <div className='item'>
                 <Link to="/" className="item_grid itemContent" style={{ backgroundImage: `url(${ItemImg2})` }}>
                     <h4>Sky Silent Hills</h4>
                     <h6>Spiti, India</h6>   
                 </Link>
            </div>

        </OwlCarousel>
    )
}

export default OwlHero;