import React from 'react';
import Gallery from 'react-photo-gallery';
import ImgGal1 from '../images/insta-1.jpg';
import ImgGal2 from '../images/insta-2.jpg';
import ImgGal3 from '../images/insta-3.jpg';
import ImgGal4 from '../images/insta-4.jpg';
import ImgGal5 from '../images/insta-5.jpg';
 


function FeaturedGallery(){

  const url = 'https://www.instagram.com/graphql/query/?query_hash=42323d64886122307be10013ad2dcc44&variables={%22id%22:%222035862796%22,%22first%22:5}';
  console.log(url);
const imgs = [
    {
      src: ImgGal1,
      width: 1,
      height: 1,
      margin:0      
    },
    {
      src: ImgGal2,
      width: 1,
      height: 1,
      margin:0      
    },
    {
      src: ImgGal3,
      width: 1,
      height: 1,
      margin:0      
    },
    {
      src: ImgGal4,
      width: 1,
      height: 1,
      margin:0      
    },
    {
      src: ImgGal5,
      width: 1,
      height: 1,
      margin:0      
    }
     
  ];
  
return(
 
  <Gallery photos={imgs} />
   );
};

export default FeaturedGallery;
