import React from 'react';
import {Link} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Logo from '../images/yatri-med-dark.svg';
import { CgMenuRight } from 'react-icons/cg'

function Nav(){

    return(
        <nav className="navbar navbar-expand-lg"  style={{backgroundColor:'transparent'}}>
            <Link className="navbar-brand" to="/">
                <img src={Logo} alt='' />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <CgMenuRight />
            </button>
            <div className="collapse navbar-collapse" id="guestUser">
            <ul className="navbar-nav m-auto">
                <li className="nav-item">
                 <NavLink className="nav-link" activeClassName="active"  to="/">Home</NavLink>
                </li>
                {/* <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/Explore">Explore</NavLink>
                </li>
                <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/AboutUs">About Us</NavLink>
                </li> */}
                <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/ContactUs">Contact Us</NavLink>
                </li>
                <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/Login">Login</NavLink>
                </li>
            </ul>
          
            </div>
      </nav>
    )
}

export default Nav;