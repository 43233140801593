// import logo from './logo.svg'; // define images link this

import './styles/styles.css';
import Nav from './components/Nav';
import Home from './components/Home';
import Explore from './components/Explore';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Login from './components/Login';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';




function App() {
  return (
    <Router>
    <div className="App">
      <header className="wb-header">
        <Nav />
      </header> 
        <Switch>
        <Route className="nav-link" path="/" exact component={Home} />
        <Route className="nav-link" path="/Explore" exact component={Explore} />
        <Route className="nav-link" path="/AboutUs" exact component={AboutUs} />
        <Route className="nav-link" path="/ContactUs" exact component={ContactUs} />
        <Route className="nav-link" path="/Login" exact component={Login} />
        </Switch>
           
    </div>
    </Router>
    
  );
}

export default App;
