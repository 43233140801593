import React from 'react';
import TabsHero from './TabsHero';
import { Link } from 'react-router-dom';
import { FaMountain } from 'react-icons/fa';
import { FaHiking } from 'react-icons/fa';
import { GiAncientRuins } from 'react-icons/gi';
import { FaHotel } from 'react-icons/fa';
// import { GiJumpAcross } from 'react-icons/gi';
import { FiSearch } from 'react-icons/fi';
import aboutImg from '../images/about-thumb.jpg';
import bigVer from '../images/big-vertical.jpg';
import smallVer from '../images/small-vertical.jpg';
import wSample from '../images/w-sample.jpg';
import tripAdv from '../images/trip-adv.png';
import icon from '../images/icon.png';
import Caraousal_Testimonial from './CarausalTestimonial';
import OpenWeather from './OpenWeather';
import FeaturedGallery from './FeaturedGallery';
import FooterMain from './FooterMain';


function Home(){  

    return (
        <div className="wb-homepage">
            <section className="hero_section">
                <section className="hero_title">
                    <h1>Plan, Travel and Dairy of True Wanderic </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div className="hero_search">
                        <div>
                            <input type="text" placeholder="Search ..." />
                        </div>
                        <button><span><FiSearch /></span></button>
                    </div>
                </section>

                <section className="hero_Content">
                    <h2>Hot Spots <span>Now</span></h2>
                    <div className="hero_tabs">
                        <TabsHero />                         
                    </div>

                    <div className="hero_popularPlaces">
                        <h3>Popular Places</h3>
                        <Link to="/">View More</Link>
                        <ul>
                            <li>
                                <Link to="/">    
                                <span><FaMountain /></span>
                                <h4>Mystic</h4>
                                <p>14 Places</p>
                                </Link>
                            </li>

                            <li>
                                <Link to="/">    
                                <span><FaHiking /></span>
                                <h4>Hiking</h4>
                                <p>9 Places</p>
                                </Link>
                            </li>

                            <li>
                                <Link to="/">    
                                <span><GiAncientRuins /></span>
                                <h4>Ancient</h4>
                                <p>19 Places</p>
                                </Link>
                            </li>

                            <li>
                                <Link to="/">    
                                <span><FaHotel /></span>
                                <h4>Luxury</h4>
                                <p>10 Places</p>
                                </Link>
                            </li>

                            {/* <li>
                                <Link to="/">    
                                <span><GiJumpAcross /></span>
                                <h4>Adventure</h4>
                                <p>21 Places</p>
                                </Link>
                            </li> */}

                            
                        </ul>
                    </div>
                </section>
            </section>

                            {/*   section About site Yatri    */}

            <section className="section__about">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 align-self-center">
                            <img src={aboutImg} alt="" />
                        </div>

                        <div className="col-md-6 align-self-center text-section pl-5 pr-5 mt-sm-5">
                            <h2>ABOUT YATRI.ME</h2>
                            <p>Lorem ipsum dolor sit amet, con secte tur adip si cin elit, do eius mod tempor in cidi dut ut la bore magna aliquat enim ad.</p>
                            <Link className="section__button" to="/AboutUs">Learn More</Link>

                        </div>
                    </div>


                    <div className="row spacingTop150 justify-content-center">

                        <div className="col-md-5 align-self-center text-section pl-5 pr-5 mt-sm-4 mb-sm-4">
                            <h2 className="position-relative"><span className="toolText cool">Supercool</span>Lifeline or Timeline!</h2>
                            <p>Lorem ipsum dolor sit amet, con secte tur adip si cin elit, do eius mod tempor in cidi dut ut la bore magna aliquat enim ad.</p>
                            <Link className="section__button" to="/AboutUs">Learn More</Link>
                        </div>    

                        <div className="col-md-4 align-self-center hide-small">
                            <img src={bigVer} alt="" />
                        </div> 

                        <div className="col-md-3 align-self-center hide-small">
                            <img src={smallVer} alt="" />
                        </div>

                    </div>  
                </div>                
                
            </section>    


             {/*   section About site Yatri    */}

            <section className="section__Blogs pt-sm-1">

            <div className="col__blog">

                <div className="col__blog-img">
                <a className="">
                    <img src={smallVer} alt="" />     
                </a>   
                </div>

                <div className="col__blog-content">
                    <div className="col__blog-content-inner">
                    <small>Jan 10, 2020</small>
                    <h2>Far top from Home</h2>
                    <p>Lorem ip sum dolor sit amet, vidit de li catae ut mei justo quando de core</p>
                    <span>MIAMI BEACH, FLORIDA</span>
                </div>
                </div>                     
                </div>

                 <div className="col__blog">

                    <div className="col__blog-img">
                    <a className="">
                        <img src={smallVer} alt="" />     
                    </a>   
                    </div>

                    <div className="col__blog-content">
                        <div className="col__blog-content-inner">
                        <small>Jan 10, 2020</small>
                        <h2>Far top from Home</h2>
                        <p>Lorem ip sum dolor sit amet, vidit de li catae ut mei justo quando de core</p>
                        <span>MIAMI BEACH, FLORIDA</span>
                    </div>
                    </div>                     
                    </div>


                 <div className="col__blog">

                     <div className="col__blog-img">
                        <a className="">
                            <img src={smallVer} alt="" />     
                        </a>   
                     </div>

                     <div className="col__blog-content">
                         <div className="col__blog-content-inner">
                            <small>Jan 10, 2020</small>
                            <h2>Far top from Home</h2>
                            <p>Lorem ip sum dolor sit amet, vidit de li catae ut mei justo quando de core</p>
                            <span>MIAMI BEACH, FLORIDA</span>
                        </div>
                     </div>                     
                 </div>


                 <div className="col__blog">
                     <div className="col__blog-img">
                        <a className="">
                            <img src={smallVer} alt="" />     
                        </a>   
                     </div>       

                     <div className="col__blog-content">
                         <div className="col__blog-content-inner">
                            <small>Jan 10, 2020</small>
                            <h2>Far top from Home</h2>
                            <p>Lorem ip sum dolor sit amet, vidit de li catae ut mei justo quando de core</p>
                            <span>MIAMI BEACH, FLORIDA</span>
                        </div>
                     </div>                     
                 </div>

            </section>  


            {/* process section  */}        

        <section className="feature__process text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <h2>Conquer Your Cravings</h2>
                    <p>Ex gubergren consequat forensibus mea, primis tritani dissentiunt ad mea, per 
                    id habeo utamur erroribus.</p>
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm-6 col-md-3 mt-5">
                        <div className="feature__icons">
                            <img src={icon} alt="" />
                            <h4 className="mt-3">Join its free</h4>
                            <p>Lorem ipsum dolor sit amet, con ctetur ad ip isicing elit, sed do 
                                eiusmod tem por incidi sit.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mt-5">
                        <div className="feature__icons">
                            <img src={icon} alt="" />
                            <h4 className="mt-3">easy new map pins</h4>
                            <p>Lorem ipsum dolor sit amet, con ctetur ad ip isicing elit, sed do 
                                eiusmod tem por incidi sit.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mt-5">
                        <div className="feature__icons">
                            <img src={icon} alt="" />
                            <h4 className="mt-3">save the raids</h4>
                            <p>Lorem ipsum dolor sit amet, con ctetur ad ip isicing elit, sed do 
                                eiusmod tem por incidi sit.</p>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-3 mt-5">
                        <div className="feature__icons">
                            <img src={icon} alt="" />
                            <h4 className="mt-3">write your stories</h4>
                            <p>Lorem ipsum dolor sit amet, con ctetur ad ip isicing elit, sed do 
                                eiusmod tem por incidi sit.</p>
                        </div>
                    </div>

                    
                </div>
            </div>
        </section>     


        {/* write stories and instant share */} 

        <section className="section__about pt-3">
                
                <div className="container">
                    <div className="row justify-content-center">                        

                        <div className="col-md-6 align-self-center text-section">
                            <h2>Let the world read your odyssey</h2>
                            <p> Had a great trip ? Let the world experience your journey. Share the excitement and joy you had at the destiny, the way you had it. Its way easier then it looks. One easy login and you can write and share anywhere.</p>
                            <Link className="section__button" to="/login">Quick Login</Link>
                        </div>

                        <div className="col-md-6 align-self-center">
                            <img className="" src={aboutImg} alt="" />
                        </div>    


                    </div>                     
                </div>                
                
            </section>

            {/* testimonials */}

            <section className="home__testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-sm-4">
                            <h3 className="text-light mt-4">Leave your Review or write us thoughts.</h3>
                            <Link to="/contactus">Write Us</Link>
                        </div>

                        <div className="col-md-8">       
                            <Caraousal_Testimonial />
                        </div>
                    </div>
                </div>
            </section>


            {/* =============== react open wheather =============  */}

            <section className="location__Weather">
                <div className="container">
                    <div className="row align-items-center">
                            <div className="col-md-5 align-item-center">
                                <OpenWeather />
                            </div>

                            <div className="image__section pl-5 col-md-7 pl-sm-3 pr-sm-3 mt-sm-5">
                                <img className="w-100" src={wSample} alt="" />
                            </div>
                    </div>
                </div>           
            </section>


            {/* =============== react open wheather =============  */}

            <section className="trip__Adv">
                <div className="container">
                    <div className="row justify-content-center">
                            <div className="col-md-8 text-center">
                                <img src={tripAdv} alt="" />
                            </div>

                            <div className="col-md-8 text-center pt-4">
                                <p className="text-transform-none">We don't own data, we repersent your experience and views with different locations and we respect that. Thats why we are going to partner with Trip Advisor.<br /> <strong>CHEERS!!</strong></p>
                            </div>
                    </div>
                </div>           
            </section>   

            {/* ==================== Phto gallery ===================  */}   

            <section className="featured__Gallery">
                <FeaturedGallery />
            </section>  
            
            <FooterMain />                        

        </div>
    )
}

export default Home;