import React from 'react';
import {Link} from 'react-router-dom';

 function onSubmit(event){
     event.preventDefault();
 };
// toggleClass() { 
//     alert('Are you sure you want remove all?');
// };

// const toggleButton = document.getElementsByClassName('formToggle');
// const toggleContainer = document.getElementsByClassName('Login__Form');
// console.log(toggleButton,toggleContainer);

// toggleButton.onClick(function(){
//     toggleContainer.closest('.page__login').classList.toggle('hidden')
//     toggleContainer.closest('.page__login').classList.toggle('hidden')
// }) 

function Login(){ 
    

    return(
        <div className="page__login">
            <div className="Login__Form">
                <form action="">
                    <h4>Login</h4>
                    <input type="text" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <div className="text-right mb-2"><span><Link to="/">Forgot Password ?</Link></span></div>
                    <button type="submit" onClick={onSubmit}>Submit</button>
                </form>
                <p className="text-center">Don't have account ? <button className="formToggle">Sign Up</button></p>
            </div> 
            <div className="Login__Form register__Form hidden">
            <form action="">
                    <h4>Register</h4>
                    <input type="text" placeholder="First Name" />
                    <input type="text" placeholder="Last Name" />
                    <input type="text" placeholder="Email" />
                    <input type="text" placeholder="Contact Number" />
                    <input type="password" placeholder="Password" />
                    <input type="password" placeholder="Confirm Password" />
                    <button type="submit" onClick={onSubmit}>Submit</button>
                </form>
                <p className="text-center">Already have account ? <button className="formToggle">Login</button></p>
            </div>
        </div>
    )
}



export default Login;