import React from 'react';



function Explore(){
    return(
        <h1>This is Explore Page</h1>
    )
}

export default Explore;