import React from 'react';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import OwlHero from './OwlHero';

function TabsHero(){ 
      return (
        <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
            <Tab label="Where's The Snow">
                <OwlHero />
            </Tab>

            <Tab label="Silent Shadow Hills">
              <OwlHero />
            </Tab>

            <Tab label="Saved Bucket List">
              <h4>Saved Buket List</h4>
              <p>Snippet Will contains wishlished places to add plans reminder or perform edit delete or update place/Info of reminder. functionalitis which can be added. <br />
              1) Add planned date. 2) Add Plan Title 3) Add Some info 4) Add Calender Reminder 5) Email Reminder 6) edit/delete/update plan/info.</p>
            </Tab>
        </Tabs>
      )
    }
   


  export default TabsHero;