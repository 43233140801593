import React from 'react';
import { Link } from 'react-router-dom';

const Copyright= () => {
    return (
        <section className="copyright__footer">
            <div className="container">
                <div className="col-12 text-center">
                    <p className="text-light small">
                        Copyright &copy; 2021 | Yatri.me | <Link to="/">Terms &amp; Conditions</Link>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Copyright;