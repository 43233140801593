import React from 'react';
import ReactWeather, { useOpenWeather } from 'react-open-weather';

const Open_Weather = () => {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '3878d5b1f65f04713b2622281cbc2ad9',
    lat: '31.1048',
    lon: '77.1734',
    city: 'Himachal Pradesh',
    country: 'India',
    lang: 'en',
    unit: 'metric', // values are (metric, standard, imperial)
  });
  return (
    <ReactWeather
      isLoading={isLoading}
      errorMessage={errorMessage}
      data={data}
      lang="en"
      locationLabel="Himachal, Pradesh"
      unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
      showForecast
    />
  );
};

export default Open_Weather;