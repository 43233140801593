import React from 'react';
import { Link } from 'react-router-dom';
import Copyright from './Copyright';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { FiMail } from 'react-icons/fi';
import { FiMapPin } from 'react-icons/fi';
import { GrFormNextLink } from 'react-icons/gr';

const FooterMain =() => {
    return(
        <footer className="Footer__Main">
            <div className="container">
                <div className="row">

                    <div className="col-md-3">
                        <div className="footer__block">
                            <h3>ABOUT</h3>
                            <p>Catch the perfect wave with WaveRide, a true haven for everything surf 
related that’s guaranteed to provide you with a genuinely smooth ride.</p>
                            <ul className="footer__social">
                                <li><Link to="/"><FaFacebookF /></Link></li>
                                <li><Link to="/"><FaTwitter /></Link></li>
                                <li><Link to="/"><FaInstagram /></Link></li>
                            </ul>    
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="footer__block">
                            <h3>CONTACT</h3>                             
                            <ul className="footer__links">
                                <li><Link to="/"><FiPhoneCall /> +44 987654 321</Link></li>
                                <li><Link to="/"><FiMail /> info@yatrime.com</Link></li>
                                <li><Link to="/"><FiMapPin /> Manali, India</Link></li>
                            </ul>    
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="footer__block">
                            <h3>Usefull Links</h3>                             
                            <ul className="footer__links">
                                <li><Link to="/"><GrFormNextLink /> About Us</Link></li>
                                <li><Link to="/"><GrFormNextLink /> Contact Us</Link></li>
                                <li><Link to="/"><GrFormNextLink /> Privacy Policy</Link></li>
                            </ul>    
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="footer__block">
                            <h3>Usefull Links</h3>                             
                            <ul className="footer__links">
                                <li><Link to="/"><GrFormNextLink /> About Us</Link></li>
                                <li><Link to="/"><GrFormNextLink /> Contact Us</Link></li>
                                <li><Link to="/"><GrFormNextLink /> Privacy Policy</Link></li>
                            </ul>    
                        </div>
                    </div>


                </div>
            </div>


            <Copyright />
        </footer>
        
    )
}

export default FooterMain;